import { Flex, Image, useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent } from 'react';

const ErrorPage: FunctionComponent = () => {
  const { tokens } = useTheme();
  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      <View marginTop={tokens.space.medium}>
        <Image
          src="https://www.honeassets.com/files/hone-logo-symbol.svg"
          alt="Hone"
          width={100}
        />
      </View>
      <View
        backgroundColor="var(--amplify-components-authenticator-router-background-color)"
        boxShadow="var(--amplify-components-authenticator-router-box-shadow)"
        border="var(--amplify-components-authenticator-router-border-color) var(--amplify-components-authenticator-router-border-width) var(--amplify-components-authenticator-router-border-style)"
        fontSize={tokens.fontSizes.large}
        marginTop={tokens.space.medium}
        marginRight={tokens.space.small}
        marginLeft={tokens.space.small}
        padding={tokens.space.large}
        textAlign="center"
        maxWidth={560}
      >
        Auth request not configured correctly
      </View>
    </Flex>
  );
};

export default ErrorPage;
